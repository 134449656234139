import Head from "next/head";
import { useRouter } from "next/router";
import styled from "styled-components";
import Hero from "@/components/Hero";
// import Form from "@/components/Form";

interface MetaTag {
  image: string;
  url: string;
  description: string;
  title: string;
}

export default function Home(): JSX.Element {
  const metaTag: MetaTag = {
    image:
      "https://firebasestorage.googleapis.com/v0/b/portfolio-83773.appspot.com/o/Media%2FProjects%2FAskMarcus%2Fask-marcus-banner.jpg?alt=media&token=55c0623c-8fb6-4c6f-83aa-c10ec08342c8",
    url: "https:/www.askmarcus.app",
    description:
      "Step into the world of Stoicism with an AI chatbot for Marcus Aurelius. Engage in meaningful conversations, discover ancient wisdom, and gain valuable insights.",
    title: "Ask Marcus - Conversations with Marcus Aurelius",
  };

  const router = useRouter();

  const canonicalUrl = (
    `https://www.askmarcus.app` + (router.asPath === "/" ? "" : router.asPath)
  ).split("?")[0];

  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:image" content={metaTag.image} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="1024" />
        <meta property="og:url" content={metaTag.url} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={metaTag.image} />
        <meta name="twitter:description" content={metaTag.description} />
        <meta property="og:description" content={metaTag.description} />
        <meta name="description" content={metaTag.description} />

        <title>{metaTag.title}</title>
        <meta property="og:title" content={metaTag.title} key="title" />
        <meta name="twitter:title" content={metaTag.title} />
      </Head>
      <Main>
        <Hero />
        {/* <Form /> */}
      </Main>
    </>
  );
}

const Main = styled.main``;
