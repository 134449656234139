import Link from "next/link";
import Image from "next/image";
import { IoAddSharp } from "react-icons/io5";
import {
  Section,
  Row,
  Column1,
  ChatLink,
  Title,
  LogoWrap,
  ImgWrap,
  Intro,
  Blurb,
  Disclaimer,
  IconWrap,
} from "./HeroElements";
import LogoWhite from "@/images/logo-white.svg";
import LogoBlack from "@/images/logo-black.svg";

export default function Hero(): JSX.Element {
  return (
    <>
      <Section id="hero" className="section">
        <Row>
          <Column1>
            <Title>
              Welcome to{" "}
              <LogoWrap>
                <ImgWrap className="logo-white">
                  <Link href="/" passHref>
                    <Image
                      alt="Ask Marcus"
                      src={LogoWhite}
                      width="250"
                      height="250"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                      }}
                      priority
                    />
                  </Link>
                </ImgWrap>
                <ImgWrap className="logo-black">
                  <Link href="/" passHref>
                    <Image
                      alt="Ask Marcus"
                      src={LogoBlack}
                      width="250"
                      height="250"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                      }}
                      priority
                    />
                  </Link>
                </ImgWrap>
              </LogoWrap>
            </Title>
            <Intro>Ask Marcus is an AI chatbot for Marcus Aurelius.</Intro>
            <Blurb>
              Step into the world of Stoicism with OpenAI&apos;s GPT-4 model.
              Engage in meaningful conversations, discover ancient wisdom, and
              gain valuable insights.
            </Blurb>
            <Link href="/chat" passHref>
              <ChatLink className="bg-gray hover-orange">
                New chat{" "}
                <IconWrap>
                  <IoAddSharp />
                </IconWrap>
              </ChatLink>
            </Link>
            <Disclaimer>
              *Please note that the free version allows for up to 10 messages
              per user. We&apos;re actively working on a premium option that
              will enable unlimited messaging with Marcus. Stay tuned for
              updates!
            </Disclaimer>
          </Column1>
        </Row>
      </Section>
    </>
  );
}
