import styled from "styled-components";

export const Section = styled.section`
  width: calc(100% - 10vw);
  height: 100vh;
  max-height: -webkit-fill-available;
  max-width: 900px;
  margin: 0 auto;
`;

export const Row = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-areas: "col1";
  height: 100%;
`;

export const Column1 = styled.div`
  width: 100%;
  grid-area: col1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ChatLink = styled.div`
  padding: 0.5rem;
  margin: 1.5rem 0 0 0;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  z-index: 1;
  text-align: center;
  width: fit-content;
`;

export const Title = styled.div`
  font-size: clamp(1rem, 10vw, 1.75rem);
  font-weight: bold;
  display: flex;
  align-items: center;

  @media screen and (max-width: 480px) {
    display: block;
  }
`;

export const LogoWrap = styled.div``;

export const ImgWrap = styled.div`
  padding: 0 0 0 12px;
  margin: -40px 0 0 0;

  @media screen and (max-width: 480px) {
    margin: -20px 0 0 0;
    padding: 0;
  }
`;

export const Intro = styled.h3``;

export const Blurb = styled.div`
  font-size: 1.25rem;
`;

export const Disclaimer = styled.div`
  font-size: 0.75rem;
  padding: 1rem 0 0 0;
`;

export const IconWrap = styled.div`
  display: inline-flex;
  vertical-align: -1px;
  padding: 0 0 0 60px;
`;
